
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { useRoute } from 'vue-router';
import * as Yup from 'yup';
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import { object } from 'yup/lib/locale';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'batch_update_drawer',
  components: {
    Field,
  },
  data() {
    return {
      moment: '' as any,
      api_url: '',
      url: '',
      load: false,
      loading: false,
      showBatchDelails: false,
      showBatchAlert: false,
      batchInfoData: {} as any,
      batchId: '',
      tranche_id: '',
      entity_id: '',
      employeeInfo: [],
      institute_id: '',
      course_id: '',
      trainerInfo: [],
      classtype: [
        {
          id: 1,
          name: 'classwork',
        },
        {
          id: 2,
          name: 'internship',
        },
      ],

      termDetails: [] as any,
      details: {
        sequence_number: 0,
        batch_number: '' as any,
        training_location: '',
        training_capacity: '',
        start_date: '',
        end_date: '' as any,
      },
      term: Array(),
      tempObject: {
        term: '',
        start_date: '',
        end_date: '',
        class_type: '',
        term_day: '',
        term_session: '',
        term_hour: '',
        week_session: '' as any,
        day: [] as any,
        training_room: [] as any,
        start_time: [] as any ,
        end_time: [] as any,
        duration_hour: [] as any,
        lead_trainer: [] as any,
        associate_trainer: [] as any,
      },
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    this.emitter.on('batch-update', async (data) => {
      this.batchId = data;
      this.getBatchInfo();
    });
  },
  methods: {
    async getBatchInfo() {
      this.loading = true;
      await ApiService.get('batch/show/' + this.batchId)
        .then((response) => {
          this.loading = false;
          this.batchInfoData = response.data.data;
          this.tranche_id = response.data.data.tranche_id;
          this.entity_id = response.data.data.entity_id;
          this.institute_id = response.data.data.institute_info_id;
          this.course_id = response.data.data.course_info_id;
          this.getCalenderInfo();

          this.details.sequence_number = response.data.data.batch_sequence_number;
          this.details.batch_number = response.data.data.batch_number;
          this.details.training_location = response.data.data.training_location;
          this.details.training_capacity = response.data.data.training_capacity;
          this.details.start_date = response.data.data.start_date;
          this.details.end_date = response.data.data.end_date;
         
          this.term = [];

          for (let i = 1; i <= response.data.data.total_term; i++) {
            if(response.data.data.term_details[i]?.start_date){
               this.tempObject = {
                  term: '',
                  start_date: '',
                  end_date: '',
                  class_type: '',
                  term_day: '',
                  term_session: '',
                  term_hour: '',
                  week_session: '' as any,
                  day: [] as any,
                  training_room: [] as any,
                  start_time: [] as any ,
                  end_time: [] as any,
                  duration_hour: [] as any,
                  lead_trainer: [] as any,
                  associate_trainer: [] as any,
              };
              this.tempObject.start_date = response.data.data.term_details[i]?.start_date;
              this.tempObject.end_date = response.data.data.term_details[i]?.end_date;
              this.tempObject.term_day = response.data.data.term_details[i]?.term_day;
              this.tempObject.class_type = response.data.data.term_details[i]?.class_type;
              this.tempObject.term_session = response.data.data.term_details[i]?.term_session;
              this.tempObject.term_hour = response.data.data.term_details[i]?.term_hour;
              this.tempObject.week_session = response.data.data.term_details[i]?.details.length;

              for (let j = 0; j < this.tempObject.week_session; j++) {
               
                this.tempObject.day.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.training_day)));
                this.tempObject.training_room.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.training_location)));
                this.tempObject.start_time.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.start_time)));
                this.tempObject.end_time.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.end_time)));
                this.tempObject.duration_hour.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.daily_hour)));
                this.tempObject.lead_trainer.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.master_trainer_id)));
                this.tempObject.associate_trainer.push(JSON.parse(JSON.stringify(response.data.data.term_details[i]?.details[j]?.associate_trainer_id)));
                this.tempObject.term = response.data.data.term_details[i]?.details[j]?.term_no;
              }
              this.term.push(JSON.parse(JSON.stringify(this.tempObject)));
            }
          }
          //this.getEmployeeList();
          this.getTrainerList();
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getCalenderInfo() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_TRAINING_CALENDAR_DETAILS_API +
          '?batch_info_id=' +
          this.batchId
      )
        .then((response) => {
          this.loading = false;
          if (response.data.data.length > 0) {
            this.showBatchDelails = false;
            this.showBatchAlert = true;
          } else {
            this.showBatchDelails = true;
            this.showBatchAlert = false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      formData.set('batch_info_id', this.batchId);
      for (var key in this.details) {
        formData.set(key, this.details[key]);
      }
      
      for (let i = 0; i < this.term.length; i++) {
        let day = Object.assign({}, this.term[i].day);
        this.term[i].day = day;
        let training_room = Object.assign({}, this.term[i].training_room);
        this.term[i].training_room = training_room;
        let start_time = Object.assign({}, this.term[i].start_time);
        this.term[i].start_time = start_time;
        let end_time = Object.assign({}, this.term[i].end_time);
        this.term[i].end_time = end_time;
        let duration_hour = Object.assign({}, this.term[i].duration_hour);
        this.term[i].duration_hour = duration_hour;
        let lead_trainer = Object.assign({}, this.term[i].lead_trainer);
        this.term[i].lead_trainer = lead_trainer;
        // let co_ordinator = Object.assign({}, this.term[i].coordinator);
        // this.term[i].coordinator = co_ordinator;
        let associate_trainer = Object.assign(
          {},
          this.term[i].associate_trainer
        );
        this.term[i].associate_trainer = associate_trainer;
        // let class_type = Object.assign({}, this.term[i].class_type);
        this.term[i].class_type = this.term[i].class_type;
      }
      formData.set('term', JSON.stringify(this.term));
      ApiService.post('batch/update', formData)
        .then((response) => {
          this.load = false;
          console.log(response);
          if (response?.data?.status == 'success') {
            Swal.fire({
              title: 'Success',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              this.emitter.emit('batch-list-updated', true);
            });
          } else {
            // let err = "";
            // for (const field of Object.keys(response.data.errors)) {
            //   err += response.data.errors[field][0] + "<br>";
            // }
            Swal.fire({
              title: 'Error!',
              html: response.data.message,
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch((response) => {
          //console.log(response);
          this.load = false;
          Swal.fire({
            title: 'Unknown Error',
            html: 'please check all required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    async getTrainerList() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_TRAINER_LIST_API +
          '?tranche_id=' +
          this.tranche_id +
          '&entity_info_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_id +
          '&course_info_id=' +
          this.course_id +
          '&active_status=1'
      )
        .then((response) => {
          this.loading = false;
          this.trainerInfo = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
        });
    },
     getDuration(endtime, starttime, index, i, session, hour) {
      if (endtime && starttime) {
        var durationInMinutes = moment(endtime, 'hh:mm A').diff(moment(starttime, 'hh:mm A'),'minutes');
        var durationInHours = durationInMinutes / 60;
        this.term[index].duration_hour[i] = durationInHours.toFixed(2);
        let durationCount = hour / session;
        
        if (durationCount > this.term[index].duration_hour[i]) {
          Swal.fire({
            title: 'Duration Error',
            text: 'Class duration does not match with course setup',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          }).then(() => {
             this.term[index].end_time[i] = '';
             this.term[index].duration_hour[i] = '';
          });
        }
      }
    },
    checkDuration(endtime, starttime, index, i, session, hour) {
      let durationCount = hour / session;

      if (durationCount > this.term[index].duration_hour[i]) {
        Swal.fire({
          title: 'Duration Error',
          text: 'Class duration does not match with course setup',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        }).then(() => {
          this.term[index].start_time[i] = '';
          this.term[index].end_time[i] = '';
          this.term[index].duration_hour[i] = '';
        });
      }
    },
    getDay(day, index, i) {
        this.term[index].day.forEach((dy, inx) => {
          if (i != inx && day == dy) {
            Swal.fire({
              title: 'Warning!',
              html: 'Day should be unqiue.',
              icon: 'warning',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            }).then(() => {
              this.term[index].day[i] = '';
            });
          }
        });
    },
    async getEmployeeList() {
      await ApiService.get(
        'institute/institute_employee' +
          '?tranche_id=' +
          this.tranche_id +
          '&entity_info_id=' +
          this.entity_id +
          '&institute_info_id=' +
          this.institute_id +
          '&active_status=1'
      )
        .then((response) => {
          this.employeeInfo = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
  setup() {},
});
